import { render, staticRenderFns } from "./ErrorNoData.vue?vue&type=template&id=af11e722&scoped=true&"
import script from "./ErrorNoData.vue?vue&type=script&lang=js&"
export * from "./ErrorNoData.vue?vue&type=script&lang=js&"
import style0 from "./ErrorNoData.vue?vue&type=style&index=0&id=af11e722&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af11e722",
  null
  
)

export default component.exports