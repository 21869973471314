<template>
  <div class="card br">
    <div class="wrapper">
      <div class="comment br animate w80"></div>
      <div class="comment br animate"></div>
      <div class="comment br animate"></div>
      <div class="comment br animate"></div>
    </div>
  </div>
</template>
<script>
import { BRow, BCol, BTab, BTabs, BCard } from "bootstrap-vue";
export default {
  name: "LoadingScreen",
  components: { BRow, BCol },
  setup() {
    const baseURL = `${window.location.origin}/load.gif`;

    return {
      baseURL,
    };
  },
};
</script>
<style scoped>
.br {
  border-radius: 8px;
}
.w80 {
  width: 80%;
}
.card {
  border: 2px solid #fff;
  box-shadow: 0px 0px 10px 0 #a9a9a9;
  padding: 30px 40px;
}
.profilePic {
  height: 65px;
  width: 120px;
}
.comment {
  height: 10px;
  background: #777;
  margin-top: 20px;
}
.wrapper {
  width: 0px;
  animation: fullView 0.5s forwards linear;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}
.animate {
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
</style>
