<template>
  <div>
    <b-card no-body style="padding: 0px">
      <b-card-body class="target-card-body">
        <b-row>
          <!--
          <b-col cols="2">
            <feather-icon
              icon="BarChartIcon"
              :class="`${data.color}-text-color`"
              style="margin-top: 0.9vw"
              width="1.8vw"
              height="1.8vw"
            />
          </b-col>
          -->
          <b-col cols="12" style="text-align:">
            <h4 :class="`text-muted m-b-0 header-text ${data.color}-text-color`">{{ $t(data.header) }}</h4>
            <h2 :class="`text-c f-w-600  value-text`" :id="`${data.header}_value`" >
              {{ count }}
            </h2>
          </b-col>
        </b-row>
      </b-card-body>

      <b-card-footer :class="`${data.color}-background card-footer`">
        <b-row>
          <b-col>
            <h6 class="text-white footer-text"  :id="`${data.header}_target_value`" >
              {{ $t('Target') + `: %${data.targetValue}` }}
            </h6>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BRow,
  BCol,
} from "bootstrap-vue";
import anime from "animejs";
import {ref,toRefs,watch} from "@vue/composition-api"
export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardFooter,
    BRow,
    BCol,
  },
  props: {
    data: {
      color: null,
      header: null,
      value: null,
      targetValue: {
        type: Number,
      },
    },
  },
  setup(props){
    const {data} = toRefs(props)
    const count = ref(0)
    setCount(data.value.value)
    watch(data,(newData)=>{
    setCount(newData.value)
      })
      function setCount(val){
      const obj = {n:count.value}
      anime({
        targets: obj,
        n: val,
        round: 1,
        duration: 1000,
        easing: 'linear',
        update: () => {
          count.value = obj.n
        }
      })
     
    }
    return {
      count
    }

  }
};
</script>
<style src="./assets/scss/widget-colors.scss" scoped lang="scss" scoped> </style>
<style scoped>
.header-text {
  text-align: center;
  width: 100%;
  font-size: clamp(0.8em,2.5vw,1.1em); 
  text-align: center;
  padding:0px;
}
.value-text {
  text-align: center;
  width: 100%;
  font-size: clamp(1.0em,2.5vw,1.25em);
  text-align: center;padding:0px;
}
.footer-text {
  text-align: center;
  font-size: clamp(0.7em,1.7vw,1.10em);
}
.card-footer{
  padding-bottom: clamp(0.5em,0.5vw,2.0em);
}
.target-card-body{
  text-align: center;
  padding: clamp(0.8em,1vw,0.9em);
  padding-bottom: clamp(0.2em,1vw,0.5em);
}
</style>