<template>
  <!-- Error page-->
  <div class="error-wrapper">
    <b-card class="error-card">
      <b-row>
        <b-col cols="auto">
          <feather-icon icon="AlertCircleIcon" style="color: rgb(255, 0, 0)" width="1.6vw" height="1.6vw" />
        </b-col>
        <b-col>
          <h4 class="error-text">Error on fetching {{ dateFilter }} data</h4>
          <h5 v-if="contextData.length > 0" class="error-text">{{ contextData }}</h5>
        </b-col>
      </b-row>
    </b-card>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BCard, BCol, BRow } from "bootstrap-vue";

import { computed, toRefs } from "@vue/composition-api";
import store from "@/store";
export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  props: {
    context: "",
  },
  setup(props) {
    const { context } = toRefs(props)
    const dateFilter = computed(() => {
      const dateFilter = store.getters["pamis/GetDateFilter"]
      if (dateFilter)
        return dateFilter.text.toLowerCase();
      return ""
    })
    const contextData = computed(() => {
      if (typeof context.value == 'string')
        return context.value
      return "";
    })
    return {
      dateFilter,
      contextData
    }
  }
};
</script>

<style scoped>
.error-card {
  border-color: red;
  background-color: rgba(255, 0, 0, 0.2);
}

.error-text {
  padding-top: 0.3vw;
  color: red;
  text-decoration: red;
}
</style>
